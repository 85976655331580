// const actions = {
//   CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
//   LOGIN_REQUEST: 'LOGIN_REQUEST',
//   LOGOUT: 'LOGOUT',
//   LOGIN_SUCCESS: 'LOGIN_SUCCESS',
//   LOGIN_ERROR: 'LOGIN_ERROR',
//   checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
//   login: payload => ({
//     type: actions.LOGIN_REQUEST,
//     payload
//   }),
//   loginSuccess: payload => ({
//     type: actions.LOGIN_SUCCESS,
//     payload
//   }),
//   logout: () => ({
//     type: actions.LOGOUT
//   })
// };
// export default actions;




const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  ACCOUNT_TYPE:'ACCOUNT_TYPE',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION, }),
  login: payload => ({
    type: actions.LOGIN_REQUEST,
    payload,
    
  }),
  loginSuccess: (token) => {
    return({
      type: actions.LOGIN_SUCCESS,
      token      
    })
  },
  addAcountType: (payload) => {
    return({
      type: actions.ACCOUNT_TYPE,
      payload:payload
      
    })
  },
  // loginSuccess: payload => {
  //   console.log("Payload => ", payload);
  //   return {
  //     type: actions.LOGIN_SUCCESS,
  //     payload,
      
  //   };
  // },
  
  logout: () => ({
    type: actions.LOGOUT
  })
};

export default actions;
