import React, { Component } from "react";
import { connect } from "react-redux";
import IntlMessages from "../../components/utility/intlMessages";
import Switch from "../../components/uielements/switch";
import Actions from "../../redux/themeSwitcher/actions.js";
import appActions from "../../redux/app/actions.js";
import Switcher from "../../components/themeSwitcher/themeSwitcher";
import LanguageSwitcher from "../LanguageSwitcher";
import Themes from "./config";
import getCurrentTheme from "./config.js";
// import { changeTheme } from '../../redux/themeSwitcher/actions.js';
import {
  Icon,
  CloseButton,
  BreadCrumbSwitch,
  ThemeSwitcherHeader,
  SwitcherBlock,
  PurchaseActionBtn,
  SidebarInner,
} from "./themeSwitcher.style";
import { stopSubmit } from "redux-form";

class ThemeSwitcher extends Component {
  // Function to handle theme change
  handleThemeChange = (attribute, themeName) => {
    const { sidebarTheme, postTextTheme } = this.props;

    // getCurrentTheme(attribute, themeName);

    console.log(attribute, themeName, "chch_test");

    // Update the local component state if necessary
    this.setState({ updatedColor: themeName });

    // Dispatch the changeTheme action with the updated theme object
    this.props.changeTheme(attribute, themeName);

    // Log the updated Redux state after dispatching the changeTheme action
    console.log("Updated Redux State:", this.props.sidebarTheme);

    console.log(sidebarTheme.themeName, "sdsd");
  };

  componentDidMount() {
    console.log("Initial Redux State:", this.props);
  }

  handleGoBack = () => {
    this.props.history.goBack(); // Go back to previous page
  };

  render() {
    const {
      topbarTheme,
      sidebarTheme,
      scrollbarTheme,
      breadCrumbTheme,
      postTextTheme,
      switchActivation,
      changeTheme,
      showBreadCrumb,
      toggleShowBreadCrumb,
      fixedNavbar,
      toggleFixedNavbar,
    } = this.props;

    // const {accountType} = this.state

    let accountType;
    // Check if token exists in localStorage
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      const parsedToken = JSON.parse(storedToken);
      // Check if accountType exists in parsedToken
      if (parsedToken && parsedToken.accountType) {
        accountType = parsedToken.accountType;
      }
    }



    return (
      <SidebarInner>
        <ThemeSwitcherHeader>
          <h3 className="componentTitle">
            <IntlMessages id="themeSwitcher.settings" />
          </h3>
        </ThemeSwitcherHeader>
        <CloseButton onClick={this.handleGoBack}>
          <Icon>close</Icon>
        </CloseButton>
        <SwitcherBlock>
          {/* <BreadCrumbSwitch>
            <h3>
              <IntlMessages id="themeSwitcher.showBreadCrumb" />
            </h3>
            <Switch checked={showBreadCrumb} onChange={toggleShowBreadCrumb} />
          </BreadCrumbSwitch> */}
          {/* <BreadCrumbSwitch>
            <h3>
              <IntlMessages id="themeSwitcher.fixedNavbar" />
            </h3>
            <Switch checked={fixedNavbar} onChange={toggleFixedNavbar} />
          </BreadCrumbSwitch> */}

          {/* <Switcher
            config={Themes.topbarTheme}
            changeTheme={changeTheme}
            selectedId={topbarTheme.themeName}
          />

          <Switcher
            config={Themes.breadCrumbTheme}
            changeTheme={changeTheme}
            selectedId={breadCrumbTheme.themeName}
          /> */}

          <Switcher
            config={Themes.postTextTheme}
            changeTheme={changeTheme}
            selectedId={postTextTheme.themeName}
          />

          <Switcher
            config={Themes.sidebarTheme}
            // changeTheme={this.handleThemeChange}
            changeTheme={changeTheme}
            selectedId={sidebarTheme.themeName}
          />
{/* 
          {accountType === "admin" && (
            <Switcher
              config={Themes.scrollbarTheme}
              changeTheme={changeTheme}
              selectedId={scrollbarTheme.themeName}
            />
          )};  */}
        </SwitcherBlock>

        {/* <PurchaseActionBtn>
          <a href="#!" className="purchaseBtn">
            <IntlMessages id="themeSwitcher.purchase" />
          </a>
        </PurchaseActionBtn> */}
      </SidebarInner>
    );
  }
}
function mapStateToProps(state) {
  const account_id = state.Auth.accountId;

  const themeData = JSON.stringify(state.ThemeSwitcher);

  localStorage.setItem(`themeData`, themeData);

  localStorage.setItem(`themeData${account_id}`, themeData);

  return {
    sidebarTheme: state.ThemeSwitcher.sidebarTheme,
    scrollbarTheme: state.ThemeSwitcher.scrollbarTheme,
    postTextTheme: state.ThemeSwitcher.postTextTheme,
    ...state.App,
    LanguageSwitcher: state.LanguageSwitcher,
  };
}

const mapDispatchToProps = {
  ...Actions,
  ...appActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSwitcher);
