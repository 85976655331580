import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import SearchFavoriteIcon from "../../assests/search-favorite-1";
import LayoutWrapper from "../../components/utility/layoutWrapper";
import { FullColumn } from "../../components/utility/rowColumn";
// import Navbar from "../NavbarOne/NavbarOne";
import Navbar from "../Navbar";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from "../LandingPageComponents/footer";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, Redirect, useHistory } from "react-router-dom";
import { logigloUrl } from "../Config";
import { parseISO, format } from "date-fns";
import { enUS } from "date-fns/locale";
import CustomIcons from "../../assests/messages";
import axios from "axios";
import "./index.css";
import ReactGA from "react-ga";
import {
  CButton,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CButtonGroup,
  CButtonToolbar,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CWidgetStatsF,
  CFormCheck,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CCardText,
  CCardTitle,
  CCardFooter,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilArrowRight,
  cilBasket,
  cilBell,
  cilChartPie,
  cilMoon,
  cilLaptop,
  cilPeople,
  cilSettings,
  cilSpeech,
  cilSpeedometer,
  cilUser,
  cilUserFollow,
} from "@coreui/icons";

// Forum Dashboard Without Login
const ForumDashboard = () => {
  // Initialize Google Analytics
  ReactGA.initialize("G-PTE475PH2N");

  // Track page view
  ReactGA.pageview(window.location.pathname + window.location.search);
  const searchText = useSelector((state) => state.Dashboard.searchText);
  const account_name = useSelector((state) => state);
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]);
  const location = useLocation();
  const history = useHistory();

  const account_id = new URLSearchParams(location.search).get("account_id");
  const account_id_number = parseInt(account_id, 10);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState("");
  const [updatedText, setUpdatedText] = useState("");
  const [updatedBusAccId, setUpdatedBusAccId] = useState("");
  const [updatingPostId, setUpdatingPostId] = useState(null);
  const [latestFilter, setLatestFilter] = useState(true);
  const [ascendingOrder, setAscendingOrder] = useState(false);
  const [postReplyCounts, setPostReplyCounts] = useState({});
  const token = useSelector((state) => state.Auth.idToken);
  const jwtToken = localStorage.getItem("token");

  const currentDate = new Date().toISOString().slice(0, 10);

  const [addsData, setAddsData] = useState([]);
  const [leftAddsData, setLeftAddsData] = useState([]);
  const [rightAddsData, setRightAddsData] = useState([]);
  const [totalNumOfPosts, setTotalNumOfPosts] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5; // Same as the limit set on the backend
  const [totalPages, setTotalPages] = useState(1);
  const [filterType, setFilterType] = useState("all");

  const [activeExperts, setActiveExperts] = useState([]);

  const [userCounts, setUserCounts] = useState({
    totalUserCount: 0,
    onlineUserCount: 0,
  });

  // advertisements API
  useEffect(() => {
    // Fetch data from the backend
    axios
      .get(`${logigloUrl}/advertisementData`)
      .then((response) => {
        setAddsData(response.data);
        console.log(response.data, "adds");
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // Posts with pagination
  const fetchData = async (page) => {
    const pageNumber = isNaN(page) ? 1 : parseInt(page);

    try {
      const url =
        filterType === "all"
          ? `${logigloUrl}/getAllPostsData?page=${pageNumber}`
          : null;

      const response = await axios.get(url);

      setPosts(response.data.posts);
      setTotalPages(Math.ceil(response.data.totalCount / postsPerPage));

      console.log(posts, "pagination_posts");
      console.log(response.data, "t_count");
      setTotalNumOfPosts(response.data.totalCount);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, filterType]);

  // Endpoint to fetch data totalUsers and onlineUsers Count
  useEffect(() => {
    const fetchUserCounts = () => {
      axios
        .get(`${logigloUrl}/userCounts`)
        .then((response) => {
          setUserCounts(response.data);
        })
        .catch((error) => {
          console.error({ "error message": error });
        });
    };

    // Fetch initially when component mounts
    fetchUserCounts();

    // Set interval to fetch user counts every 5 seconds
    const intervalId = setInterval(fetchUserCounts, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchActiveExperts = () => {
      axios
        .get(`${logigloUrl}/topActiveExperts`)
        .then((response) => {
          setActiveExperts(response.data);
          console.log(response.data, "top_users_");
        })
        .catch((error) => {
          console.error("Error fetching active experts:", error);
        });
    };

    fetchActiveExperts();
    const interval = setInterval(fetchActiveExperts, 10000); // Fetch every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  // Function to format date and time efficiently
  const formatDateTime = (dateString) => {
    const dateObject = new Date(dateString);
    return format(dateObject, "dd/MM/yyyy, hh:mm:ss a", { locale: enUS });
  };

  // Function to handle the filter posts to Latest posts
  const handleLatestFilter = (event) => {
    setLatestFilter(true); // Always set the Latest filter to true when "Latest" is clicked
    setAscendingOrder(false); // Set ascendingOrder to false to display in descending order
  };

  // Function to handle the filter posts to Oldest posts
  const handleOldestFilter = (event) => {
    setLatestFilter(false); // Always set the Latest filter to false when "Oldest" is clicked
    setAscendingOrder((prevAscendingOrder) => !prevAscendingOrder); // Toggle ascendingOrder value
    console.log(ascendingOrder);
  };

  // Function to handle navigatation to create post container
  const onClickCreatePostBtn = () => {
    history.push(`/signin?from=`);
  };

  // Function to handle cancel update post
  const handleCancelUpdatePost = () => {
    console.log("handleCancelUpdatePost triggered");
    setShowUpdatePrompt(false);
    setUpdatingPostId(null);
    setUpdatedTitle("");
    setUpdatedText("");
    setUpdatedBusAccId("");
  };

  // Function to handle update post
  const handleSaveUpdatePost = () => {
    console.log("qwertyui");
    // Ensure at least one field is updated
    if (!updatedTitle.trim() && !updatedText.trim()) {
      alert("Please enter at least one updated field.");
      return;
    } else if (updatedTitle === "") {
      alert("Title shound not empty.");
    } else if (updatedText === "") {
      alert("Text shound not empty.");
    } else {
      // Make the API call to update the post in the database
      axios
        .put(`${logigloUrl}/updatePost/${updatingPostId}`, {
          account_id: account_id,
          bus_acc_id: 1111,
          title: updatedTitle,
          text: updatedText,
          created_at: currentDate,
          last_modified: currentDate,
          last_modified_by: account_id,
          last_mod_by_bus: 1111,
        })
        .then((response) => {
          if (response.status === 200) {
            // If the post was updated successfully, update the posts list to show the updated post
            setPosts((prevPosts) =>
              prevPosts.map((post) =>
                post.id === updatingPostId
                  ? {
                      ...post,
                      bus_acc_id: updatedBusAccId,
                      title: updatedTitle,
                      text: updatedText,
                    }
                  : post
              )
            );
            // Close the update prompt
            setShowUpdatePrompt(false);
            setUpdatingPostId(null);
            setUpdatedTitle("");
            setUpdatedText("");
            setUpdatedBusAccId("");
          } else {
            console.error("Error updating post");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Function to calculate the time difference and return the absolute time difference
  const getTimeDisplayText = (postCreatedAt) => {
    const createdTime = parseISO(postCreatedAt);
    const currentTime = new Date();

    const timeDifferenceInSeconds = Math.floor(
      (currentTime - createdTime) / 1000
    );

    if (timeDifferenceInSeconds < 60) {
      // If post is created within the last minute, display "X seconds ago"
      return ` ${timeDifferenceInSeconds} second${
        timeDifferenceInSeconds !== 1 ? "s" : ""
      } ago`;
    } else if (timeDifferenceInSeconds < 3600) {
      // If post is created within the last hour, display "X minutes ago"
      const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
      return ` ${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
    } else if (
      timeDifferenceInSeconds >= 3600 &&
      timeDifferenceInSeconds < 86400
    ) {
      // If post is created more than an hour ago, display "X hours ago"
      const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
      const remainingMinutes = Math.floor(
        (timeDifferenceInSeconds % 3600) / 60
      );
      return ` ${hoursAgo} hour${
        hoursAgo !== 1 ? "s" : ""
      } ${remainingMinutes} minute${remainingMinutes !== 1 ? "s" : ""} ago`;
    } else {
      // If post is created more than 1 day ago, display formatted date and time
      return formatDateTime(postCreatedAt);
    }
  };

  // Function to get the first n words from the post Text
  const post_text_to_display = 14;
  const getFirstNWords = (text, n) => {
    const words = text.split(" ");
    const firstNWords = words.slice(0, n).join(" ");
    return firstNWords + (words.length > n ? "..." : ""); // Add "..." if there are more words in the text
  };

  const getAllPosts = () => {
    axios
      .get(`${logigloUrl}/getAllPostsData`)
      .then((response) => {
        console.log(response.data, "iii");
        setPosts(response.data); // Store the retrieved data in state
        // Fetch category names for each post
        // const postIds = response.data.map((post) => post.id);
        // // debugger;
        // fetchCategoryNames(postIds);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFilterChange = (filter) => {
    setFilterType(filter);
    setCurrentPage(1);
  };

  // console.log(posts)

  // console.log(displayPosts, "pagination");

  // Calculate the sum of login_count values
  const totalLoginCount = activeExperts.reduce(
    (sum, expert) => sum + expert.login_count,
    0
  );
  // console.log(totalLoginCount);

  return (
    <HelmetProvider>
    <Helmet>
      <title>Logiglo Forum</title>
      <meta property="og:title" content="Logiglo Forum" />
      <meta name="description" property="og:description" content="Forum Description" />
      <meta property="og:image" content="https://opengraph.b-cdn.net/production/images/f8ba140a-981c-4421-a09e-ebae95b0534f.png?token=FXByuwPXqDXbCAr2fdnElBC4FiDHbW6vOC8r-8b_Kg0&height=675&width=1200&expires=33259546757" />
      <meta property="og:url" content="https://logiglo.com/forum" />
      <meta property="og:type" content="website" />
    </Helmet>
    <LayoutWrapper>
      <Navbar />
      <FullColumn>
        <div className="forum-dashboard-outer-con">
          <div className="forum-for-all-dashboard-inner-con">
            <div className="forum-content-con">
              {/* left side adds container */}
              <div className="forum-home-left-adds-con">
                {/* <div className="forum-left-adds-con">
                  <Link to="/ContactUs">
                    <img
                      style={{ width: "100%" }}
                      src="https://res.cloudinary.com/dwwzfhucu/image/upload/v1721127581/AD_1_xnkfls.jpg"
                    />
                  </Link>
                </div> */}

                {addsData.map((item) => {
                  if (item.type === "left" && item.render) {
                    return (
                      <Link to={item.navigation_url} style={{textDecoration: 'none', color: 'black'}}>
                      <div key={item.id} className="forum-left-adds-con">
                        <img src={item.image_url} className="ad-image-styles" />
                        <h5>{item.title}</h5>
                        <p>{item.text}</p>
                      </div>
                      </Link>
                    );
                  }
                  return null;
                })}

                <div className="forum-left-nav-con">
                <Link to="/ContactUs" style={{textDecoration : 'none'}} >
                  <div className="each-icon-con-in-left-section">
                    <CustomIcons
                      base64={
                        "PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTAwMDIgNy41NTk5OUM5LjIxMDAyIDMuOTU5OTkgMTEuMDYgMi40ODk5OSAxNS4xMSAyLjQ4OTk5SDE1LjI0QzE5LjcxIDIuNDg5OTkgMjEuNSA0LjI3OTk5IDIxLjUgOC43NDk5OVYxNS4yN0MyMS41IDE5Ljc0IDE5LjcxIDIxLjUzIDE1LjI0IDIxLjUzSDE1LjExQzExLjA5IDIxLjUzIDkuMjQwMDIgMjAuMDggOC45MTAwMiAxNi41NCIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNSAxMkgzLjYyIiBzdHJva2U9IiMyOTJEMzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUuODUgOC42NDk5TDIuNSAxMS45OTk5TDUuODUgMTUuMzQ5OSIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                      }
                    />
                    <p className="left-text">Advertise</p>
                  </div>
                  </Link>

                  <Link to="/ContactUs"  style={{textDecoration : 'none'}}>
                  <div className="each-icon-con-in-left-section">
                    <CustomIcons
                      base64={
                        "PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTAwMDIgNy41NTk5OUM5LjIxMDAyIDMuOTU5OTkgMTEuMDYgMi40ODk5OSAxNS4xMSAyLjQ4OTk5SDE1LjI0QzE5LjcxIDIuNDg5OTkgMjEuNSA0LjI3OTk5IDIxLjUgOC43NDk5OVYxNS4yN0MyMS41IDE5Ljc0IDE5LjcxIDIxLjUzIDE1LjI0IDIxLjUzSDE1LjExQzExLjA5IDIxLjUzIDkuMjQwMDIgMjAuMDggOC45MTAwMiAxNi41NCIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNSAxMkgzLjYyIiBzdHJva2U9IiMyOTJEMzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUuODUgOC42NDk5TDIuNSAxMS45OTk5TDUuODUgMTUuMzQ5OSIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                      }
                    />
                    <p className="left-text">Help</p>
                  </div>
                  </Link>

                  <Link className="each-icon-con-in-left-section" to="/privacy">
                    <CustomIcons
                      base64={
                        "PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTAwMDIgNy41NTk5OUM5LjIxMDAyIDMuOTU5OTkgMTEuMDYgMi40ODk5OSAxNS4xMSAyLjQ4OTk5SDE1LjI0QzE5LjcxIDIuNDg5OTkgMjEuNSA0LjI3OTk5IDIxLjUgOC43NDk5OVYxNS4yN0MyMS41IDE5Ljc0IDE5LjcxIDIxLjUzIDE1LjI0IDIxLjUzSDE1LjExQzExLjA5IDIxLjUzIDkuMjQwMDIgMjAuMDggOC45MTAwMiAxNi41NCIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNSAxMkgzLjYyIiBzdHJva2U9IiMyOTJEMzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUuODUgOC42NDk5TDIuNSAxMS45OTk5TDUuODUgMTUuMzQ5OSIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                      }
                    />
                    <p className="left-text">Content Policy</p>
                  </Link>

                  <Link className="each-icon-con-in-left-section" to="/privacy">
                    <CustomIcons
                      base64={
                        "PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguOTAwMDIgNy41NTk5OUM5LjIxMDAyIDMuOTU5OTkgMTEuMDYgMi40ODk5OSAxNS4xMSAyLjQ4OTk5SDE1LjI0QzE5LjcxIDIuNDg5OTkgMjEuNSA0LjI3OTk5IDIxLjUgOC43NDk5OVYxNS4yN0MyMS41IDE5Ljc0IDE5LjcxIDIxLjUzIDE1LjI0IDIxLjUzSDE1LjExQzExLjA5IDIxLjUzIDkuMjQwMDIgMjAuMDggOC45MTAwMiAxNi41NCIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xNSAxMkgzLjYyIiBzdHJva2U9IiMyOTJEMzIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTUuODUgOC42NDk5TDIuNSAxMS45OTk5TDUuODUgMTUuMzQ5OSIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo="
                      }
                    />
                    <p className="left-text">Privacy Policy</p>
                  </Link>
                </div>
              </div>

              <div className="forum-middle-con">
                <div className="forum-home-content-top-con">
                  <div className="forum-home-dashboard-top-con">
                    <div className="forum-home-global-search-con">
                      <SearchFavoriteIcon />
                      <input
                        className="forum-global-search-input"
                        placeholder="Search"
                      />
                    </div>
                  </div>
                  <div className="aasas">
                    <button
                      className="post-creation-btn"
                      onClick={onClickCreatePostBtn}
                    >
                      Create post
                    </button>

                    <div className="filters-and-posts-count-con">
                     

                      <CButtonGroup
                        className="me-0"
                        role="group"
                        aria-label="First group"
                      >
                        <CButton
                          color="secondary"
                          variant="outline"
                          onClick={() => handleFilterChange("all")}
                          style={{ fontSize: "12px" }}
                        >
                          All Posts
                        </CButton>

                        <CButton
                          color="secondary"
                          variant="outline"
                          onClick={handleLatestFilter}
                          style={{ fontSize: "12px" }}
                        >
                          Latest
                        </CButton>
                        <CButton
                          color="secondary"
                          variant="outline"
                          onClick={handleOldestFilter}
                          style={{ fontSize: "12px" }}
                        >
                          Oldest
                        </CButton>
                      </CButtonGroup>
                    </div>
                  </div>
                </div>

                {posts
                  .sort((a, b) => {
                    const dateA = new Date(a.created_at);
                    const dateB = new Date(b.created_at);
                    return latestFilter ? dateB - dateA : dateA - dateB;
                  })

                  .map((post) => {
                    if (post.status === "active") {
                      return (
                        <div key={post.id} className="each-post-details-con">
                            <Helmet>
                          <title>{getFirstNWords(
                              post.title,
                              post_text_to_display
                            )}</title>
                          <meta
                            property="og:title"
                            data-react-helmet="true"
                            // content={"Logiglo Forum"}
                            content={getFirstNWords(
                              post.title,
                              post_text_to_display
                            )}
                          />
                          <meta
                            name="description"
                            property="og:description"
                            data-react-helmet="true"
                            content={getFirstNWords(
                              post.text,
                              post_text_to_display
                            )}
                          />
                          <meta
                            name="image"
                            property="og:image"
                            content="https://opengraph.b-cdn.net/production/images/f8ba140a-981c-4421-a09e-ebae95b0534f.png?token=FXByuwPXqDXbCAr2fdnElBC4FiDHbW6vOC8r-8b_Kg0&height=675&width=1200&expires=33259546757"
                          />
                          <meta
                            property="og:url"
                            content={`https://logiglo.com`}
                          />
                          <meta property="og:type" content="website" />
                        </Helmet>

                          <div className="each-post-left-con">
                            <p
                              className="each-post-title"
                              onClick={onClickCreatePostBtn}
                            >
                              {" "}
                              {getFirstNWords(post.title, post_text_to_display)}
                            </p>

                            <p className="each-post-text">
                              {getFirstNWords(post.text, post_text_to_display)}
                            </p>

                            <div className="each-post-creator-con">
                              {/* create_by not availble for old posts id DB is ready while deployment change delete following condition       */}

                              <a
                                style={{
                                  textDecoration: "none",
                                  fontSize: "14px",
                                  paddingRight: "10px",
                                  color: "#1976D2",
                                }}
                                href={`userProfile`}
                              >
                                {post.created_by}
                              </a>

                              <span className="each-post-date-and-time">
                                Posted {getTimeDisplayText(post.created_at)}{" "}
                              </span>
                            </div>
                          </div>

                          <div className="each-post-right-con">
                            <p className="replies-count">
                              {post.reply_count === 1
                                ? `${post.reply_count} reply`
                                : `${post.reply_count || 0} replies`}
                            </p>
                            <p className="answers-text">2 Answers</p>
                            <p>246 Views</p>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}

                <div className="pagination-con">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev))
                    }
                    disabled={currentPage === 1}
                    className="pagination-btn"
                  >
                    Previous
                  </button>

                  <span>
                    Page {currentPage} of {totalPages}
                  </span>

                  <button
                    onClick={() =>
                      setCurrentPage((prev) =>
                        prev < totalPages ? prev + 1 : prev
                      )
                    }
                    className="pagination-btn"
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>

                <div className="most-active-expert-details-outer-con">
                  {/* <div className="most-active-expert-details-inner-con">
      <h5>Most Active Experts</h5>
        {activeExperts.map(expert => (
          <div key={expert.id} className="most-active-expert-con">
            <img className="most-active-expert-img" src="https://cdn-icons-png.flaticon.com/512/3177/3177440.png" />
            <div className="most-active-expert-details-con">
            <p style={{color: '#1D82E8'}}>{expert.first_name}</p>
            <p>{expert.login_count} Points</p>
            </div>
          </div>
        ))}
      </div> */}
                </div>
              </div>
              {/* right side adds container */}
              <div className="forum-home-right-adds-con">
                {/* <div style={{backgroundColor: 'lightyellow'}}>
                <h5>Total Members</h5>
                <p>111</p>
                </div> */}

                <CCard style={{ marginTop: "10px", width: "100%" }}>
                  <CCardBody>
                    <CCardTitle>Members online</CCardTitle>

                    <small style={{ fontSize: "12px" }}>
                      {/* Total Posts:  {totalNumOfPosts} <br/> */}
                      {userCounts.onlineUserCount === 0
                        ? "No members online now"
                        : `Members online: ${userCounts.onlineUserCount}`}
                    </small>
                  </CCardBody>
                  <CCardFooter>
                    <small
                      style={{ fontSize: "12px" }}
                      className="text-body-secondary"
                    >
                      Total: (Members: {userCounts.totalUserCount}, All logins:{" "}
                      {totalLoginCount})
                    </small>
                  </CCardFooter>
                </CCard>

                {/* <CCol style={{ marginTop: "10px", width: "100%" }}>
                    <CWidgetStatsF
                      value={userCounts.totalUserCount}
                      icon={<CIcon width={24} icon={cilUser} size="xl" />}
                      title="Total Members"
                      color="info"
                    />
                  </CCol>

                  <CCol style={{ marginTop: "10px", width: "100%"  }}>
                    <CWidgetStatsF
                      icon={<CIcon width={24} icon={cilLaptop} size="xl" />}
                      title="Users Online"
                      value={userCounts.onlineUserCount}
                      color="warning"
                    />
                  </CCol>

                  <CCol style={{ marginTop: "10px", width: "100%" }}>
                    <CWidgetStatsF
                      icon={<CIcon width={24} icon={cilBell} size="xl" />}
                      title="Total Posts"
                      value={totalNumOfPosts}
                      color="primary"
                    />
                  </CCol> */}

                {/* <div className="forum-right-adds-con">
                <Link to="/ContactUs" >
                  <img
                    style={{ width: "100%" }}
                    src="https://res.cloudinary.com/dwwzfhucu/image/upload/v1721127655/poster_1_uhhgji.jpg"
                  />
                  </Link>
                </div> */}

                {addsData.map((item) => {
                  if (item.type === "right" && item.render) {
                    return (
                      <Link to={item.navigation_url} style={{textDecoration: 'none', color: 'black'}}>
                      <div key={item.id} className="forum-right-adds-con">
                        <img src={item.image_url} className="ad-image-styles" />
                        <h5>{item.title}</h5>
                        <p>{item.text}</p>
                      </div>
                      </Link>
                    );
                  }
                  return null;
                })}

                <div style={{ marginTop: "20px" }}>
                  <h5>Priority Questions</h5>
                  <p className="forum-adds-text">
                    Need supplier of fleet to carry 1K Tons of perishable goods
                    priority
                  </p>
                  <p className="forum-adds-text">
                    Urgent requirement of Pharmaceutical goods logistics vendor
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </FullColumn>
    </LayoutWrapper>
    </HelmetProvider>
  );
};

export default ForumDashboard;
