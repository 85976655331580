const actions = {
  Search_Text: "Search_Text",
  account_id: "account_id",
  Update_Name: "Update_Name",
  accountType : "accountType",

  updateSearchText: (name, payload, accountType) => ({
    type: actions.Search_Text,
    name,
    accountType,
    payload,
  }),
  updateName: (name, account_id, accountType) => {
    // console.log("name, account_id ",name, account_id)
    return({
      type: actions.Update_Name,
      name,
      account_id,
      accountType
    })
  },
};
export default actions;

// const actions = {
//   Search_Text: 'Search_Text',
//   Update_Name: 'Update_Name', // New action type
//   updateSearchText: (searchText) => ({
//     type: actions.Search_Text,
//     searchText,
//   }),
//   // New action creator for updating the name
//   updateName: (name, account_id) => ({
//     type: actions.Update_Name,
//     name,
//     account_id,
//   }),
// };

// export default actions;
