import Auth from './auth/reducer';
import Dashboard from './dashboard/reducer';
import App from './app/reducer';
import ThemeSwitcher from './themeSwitcher/reducer';
import LanguageSwitcher from './languageSwitcher/reducer';
import ForumDashboard from './forumDashboard/reducer'
import colorReducer from './buttonColor/reducer';

export default {
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Dashboard,
  ForumDashboard,
  colorReducer,
};
