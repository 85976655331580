import { getCurrentTheme } from '../../containers/ThemeSwitcher/config';
const actions = {
  CHANGE_THEME: 'CHANGE_THEME',
  SWITCH_ACTIVATION: 'SWITCH_ACTIVATION',
  switchActivation: isActivated => ({
    type: actions.SWITCH_ACTIVATION,
    isActivated,
  }),
  changeTheme: (attribute, themeName) => {

   

    const theme = getCurrentTheme(attribute, themeName);
    
    if (attribute === 'layoutTheme') {
      document.getElementsByClassName('mateContent')[0].style.backgroundColor =
        theme.backgroundColor;
    }

    // Retrieve accountId from localStorage
    const token = localStorage.getItem('token');
    let accountId = null;
    if (token) {
      const { accountId: parsedAccountId } = JSON.parse(token);
      accountId = parsedAccountId;
    }

    // Dispatch the action with accountId
    return {
      type: actions.CHANGE_THEME,
      attribute,
      theme,
      accountId
    };
  },
};
export default actions;
