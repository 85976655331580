import { useEffect } from 'react';
import Aos from 'aos';

const useAOS = () => {

   useEffect(() => {
    // Initialize AOS
    Aos.init({
       // Global settings:
    // offset: 100, // offset (in px) from the original trigger point
    // delay: 0, // values from 0 to 3000, with step 50ms
    duration: 2000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    });

  }, []);
};

export default useAOS;
