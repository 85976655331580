import clone from 'clone';
import config, { getCurrentTheme } from '../../containers/ThemeSwitcher/config';
import actions from './actions';




const initState = {
  isActivated: false,
  changeThemes: getCurrentTheme(
    'changeThemes',
    config.changeThemes.defaultTheme || 'themedefault'
  ),
  breadCrumbTheme: getCurrentTheme(
    'breadCrumbTheme',
    config.breadCrumbTheme.defaultTheme || 'themedefault'
  ),
  topbarTheme: getCurrentTheme(
    'topbarTheme',
    config.topbarTheme.defaultTheme || 'themedefault'
  ),
  sidebarTheme: getCurrentTheme(
    'sidebarTheme',
    config.sidebarTheme.defaultTheme || 'themedefault'
  ),
  scrollbarTheme: getCurrentTheme(
    'scrollbarTheme',
    config.scrollbarTheme.defaultTheme || 'themedefault'
  ),
  postTextTheme: getCurrentTheme(
    'postTextTheme',
    config.postTextTheme.defaultTheme || 'themedefault'
  ),
  layoutTheme: getCurrentTheme(
    'layoutTheme',
    config.layoutTheme.defaultTheme || 'themedefault'
  )
};


// Retrieve accountId from localStorage
const token = localStorage.getItem('token');
let accountId = null;
if (token) {
  const { accountId: parsedAccountId } = JSON.parse(token);
  accountId = parsedAccountId;

  console.log(accountId, "wqw")
}


// Scrolling Background Color

// const storedTheme = localStorage.getItem('themeData');


const storedTheme = localStorage.getItem(`themeData${accountId}`);

// Admin Data from localStorage
const scrollingClr = localStorage.getItem('themeData168');


console.log("all_themes", scrollingClr)

// If there's stored theme data, update all properties of initState with it
if (storedTheme) {
  const parsedTheme = JSON.parse(storedTheme);
  const parsedScrollTheme = JSON.parse(scrollingClr);

  console.log(parsedScrollTheme, "par")
  initState.changeThemes = parsedTheme.changeThemes || initState.changeThemes;
  initState.breadCrumbTheme = parsedTheme.breadCrumbTheme || initState.breadCrumbTheme;
  initState.topbarTheme = parsedTheme.topbarTheme || initState.topbarTheme;
  initState.sidebarTheme = parsedTheme.sidebarTheme || initState.sidebarTheme;
  // initState.scrollbarTheme = parsedScrollTheme.scrollbarTheme || initState.scrollbarTheme;
  initState.postTextTheme = parsedTheme.postTextTheme || initState.postTextTheme;
  initState.layoutTheme = parsedTheme.layoutTheme || initState.layoutTheme;
}


console.log(initState, "ininini");

export default function(state = initState, action) {
  console.log("theme_action2 => ",action)

  



  console.log(action.accountId, "loloppp")
  switch (action.type) {
    case actions.SWITCH_ACTIVATION:
      return { ...state, isActivated: clone(action.isActivated) };
    case actions.CHANGE_THEME:
      
      return { ...state, [action.attribute]: action.theme };
    default:
      return state;
  }
}
