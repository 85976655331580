import actions from "./actions";
import { connect, useDispatch, useSelector } from 'react-redux';
const initState = { searchText: '', name : '', accountType : "" };


export default function authReducer(state = initState, action) {
  
  switch (action.type) {
    
    case actions.Search_Text:
      return {
        searchText: action.searchText,
        account_id: action.account_id,
        name : action.name,
        accountType : action.accountType
        
        
      };
    case actions.Update_Name:
      return {
        name: action.name,
        account_id: action.account_id,
        accountType : action.accountType
      };
    default:
      return state;
  }
}


// import actions from "./actions";

// const initState = { searchText: '', name: '', account_id: '' };

// export default function authReducer(state = initState, action) {
//   switch (action.type) {
//     case actions.Search_Text:
//       return {
       
//         searchText: action.searchText,
//       };
//     case actions.Update_Name:
//       return {
        
//         name: action.name,
//         account_id: action.account_id,
//       };
//     default:
//       return state;
//   }
// }
