import React, { Component, lazy, Suspense } from 'react';
import Route from '../../components/utility/customRoute';
import Loader from '../../components/utility/Loader/';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HelmetProvider } from 'react-helmet-async';


const routes = [
  {
    path: '',
    component: lazy(() => import('../forumDashboard/index')),
  },
  {
    path: 'Posts',
    component: lazy(() => import('../forumDashboard/index')),
  },
  {
    path: 'NewUserDashboard',
    component: lazy(() => import('../DashboardForWithoutLogin/Dashboard')),
  },
  {
    path: 'admin',
    component: lazy(() => import('../admin/adminDashboard')),
  },
  {
    path: 'admin/PostModeration',
    component: lazy(() => import('../admin/adminPostModeration')),
  },
  {
    path: 'admin/ReplyModeration',
    component: lazy(() => import('../admin/adminReplyModeration')),
  },
  {
    path: 'admin/ReplyModerationPage',
    component: lazy(() => import('../admin/adminReplyModerationAction')),
  },
  {
    path: 'drafts',
    component: lazy(() => import('../drafts')),
  },
  {
    path: 'submitted-posts',
    component: lazy(() => import('../submittedPosts')),
  },
  {
    path: 'submittedReplies',
    component: lazy(() => import('../submittedReplies')),
  },
  {
    path: 'Private Page',
    component: lazy(() => import('../BillingForm')),
  },
  {
    path: 'CreateReply',
    component: lazy(() => import('../Replies/Replies')),
  },
  {
    path: 'template four',
    component: lazy(() => import('../Templatefour')),
  },
  {
    path: 'searchPage',
    component: lazy(() => import('../SearchPage/SearchPage')),
  },
  {
    path: 'admin/searchPage',
    component: lazy(() => import('../admin/adminSearchPage')),
  },
  {
    path: 'user',
    component: lazy(() => import('../userDashboard/index')),
  },
  {
    path: 'createPost',
    component: lazy(() => import('../CreatePost/CreatePost')),
  },
  {
    path: 'interestsPage',
    component: lazy(() => import('../InterestsPage/InterestsPage')),
  },
  {
    path: 'MailVerification',
    component: lazy(() => import('../MailVerificationPage/MailVerificationPage')),
  },
  {
    path: 'BussAccountVerification',
    component: lazy(() => import('../BussAccountVerificationPage/BussAccountVerificationPage')),
  },
  {
    path: 'AddBusinessAccount',
    component: lazy(() => import('../AddBusinessAccount/AddBusinessAccount')),
  },
  {
    path: 'corporateAccount',
    component: lazy(() => import('../Page/CorporateAccount/index')),
  },
  {
    path: 'verifyEmail',
    component: lazy(() => import('../verifyMail')),
  },
  {
    path: 'AdminSettings',
    component: lazy(() => import('../admin/adminControlPanel/index')),
  },
  {
    path: 'UserSettings',
    component: lazy(() => import('../userControlPanel/index')),
  },
 
  
];

class AppRouter extends Component {
  render() {
    const { url, style } = this.props;
    return (
      <HelmetProvider>
      <Suspense fallback={<Loader />}>
        <div style={style}>
          {routes.map(singleRoute => {
            const { path, exact, ...otherProps } = singleRoute;
            return (
              <Route
                exact={exact === false ? false : true}
                key={singleRoute.path}
                path={`${url}/${singleRoute.path}`}
                {...otherProps}
              />
            );
          })}
        </div>
      </Suspense>
      </HelmetProvider>
    );
  }
}

export default AppRouter;
