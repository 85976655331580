import React, { Component } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SearchFavoriteIcon from "../../assests/search-favorite-1";
import Icon from "../../components/uielements/icon";
import appActions from "../../redux/app/actions";
import themeActions from "../../redux/themeSwitcher/actions";
import { AppHolder, Toolbar, IconButtons, TopbarComponents } from "./style";
import TopbarSearch from "./topbarSearch";
// import SecondarySidebar from '../SecondarySidebar';
import TopbarNotification from "./sidebarNotification";
import TopbarUser from "./topbarUser";
import actions from "../../redux/dashboard/actions";
import { MdOutlineFeedback } from "react-icons/md";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { FaRegUserCircle } from "react-icons/fa";
import feedbackIcon from "../../images/feedbackIcon.png";
import { Link, withRouter } from "react-router-dom";

import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilBell,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const { toggleCollapsed } = appActions;
const { switchActivation } = themeActions;

// TOPBAR WORKING
const Topbar = (props) => {
  const location = useLocation();
  const account_id = new URLSearchParams(location.search).get("account_id");
  const accountType = new URLSearchParams(location.search).get("accountType");
  const account_name = useSelector((state) => state.Dashboard.name);
  // console.log(account_name, "topbar")
  const dispatch = useDispatch();

  const { toggleCollapsed, locale, url, customizedTheme, switchActivation } =
    props;
  const propsTopbar = { locale, url };
  return (
    <AppHolder style={{ height: "0%" }}>
      <Toolbar
        style={{
          // paddingLeft: '30px',
          // minHeight: '64px',
          // background: customizedTheme.topbarTheme,
          backgroundColor: "white",
          // backgroundColor : 'red',
          border: "1px solid lightgray",
          borderRight: "0px",
          
        }}
      >
        <IconButtons
          id="topbarCollapsed"
          aria-label="open drawer"
          onClick={toggleCollapsed}
          className="right"
          // style={{backgroundColor: '#155CA2'}}
        >
          <Icon>menu</Icon>
        </IconButtons>

        <TopbarComponents>
          <ul className="topbarItems">
            <li className="topbarSearch">
              <TopbarSearch
                {...propsTopbar}
                onChange={(event) => {
                  dispatch({
                    type: actions.Search_Text,
                    searchText: event.target.value,
                    account_id: account_id,
                    name: account_name,
                    accountType: accountType,
                  });

                  // console.log('text => ',event.target.value)
                }}
              />
            </li>

            {/* <li className="topbarNotification">
                <TopbarNotification {...propsTopbar} />
              </li> */}

            {/* <li href="#">
          <CIcon icon={cilEnvelopeOpen} className="me-2" />
          <Icon
                   
                    onClick={() => switchActivation('notification')}
                    style={{color: 'grey', fontSize: '20px', fontWeight: '700', marginRight: '-20px'}}
                  >
                    feedback
                  </Icon> 
         
        </li> */}

            <Link to="/ContactUs">
              <CIcon
                icon={cilEnvelopeOpen}
                className="me-2"
                style={{ color: "grey", width: "20px", height: "20px", marginRight: "-120px",  }}
              />

              {/* <CBadge color="success" className="ms-2">
            42
          </CBadge> */}
            </Link>

            <li className="topbarNotification">
              <div>
                {/* <Icon
                    onClick={() => switchActivation('notification')}
                    style={{ matginTop: 5, color : 'gray' }}
                  >
                    help
                  </Icon> */}
                  <Link to="/ContactUs">
                <IoIosHelpCircleOutline
                  style={{
                    color: "grey",
                    // fontSize: "30px",
                    fontWeight: "700",
                    marginTop: '5px',
                    marginRight: "-20px",
                    width: "23px", height: "23px"
                  }}
                />
                </Link>
              </div>
            </li>

            <li className="topbarUser">
              {/* <FaRegUserCircle style={{color: 'red'}}/> */}
              <TopbarUser location={location} {...propsTopbar} />
            </li>
          </ul>
        </TopbarComponents>
      </Toolbar>
    </AppHolder>
  );
};

export default connect(
  (state) => ({
    ...state.App,
    locale: state.LanguageSwitcher.language.locale,
    customizedTheme: state.ThemeSwitcher.topbarTheme,
  }),
  { toggleCollapsed, switchActivation }
)(Topbar);
