import React, { useEffect, useState, useRef, Suspense } from "react";
import LayoutWrapper from "../../../components/utility/layoutWrapper";
import Papersheet from "../../../components/utility/papersheet";
import axios from "axios";
import { logigloUrl } from "../../Config/index";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullColumn } from "../../../components/utility/rowColumn";
import SearchFavoriteIcon from "../../../assests/search-favorite-1";
import careers from "../../../assests/careers.svg";
import ExportIcon from "../../../assests/export";
import CareersForm from "../../postCreateForms/careersForm";
import { CCol, CButton } from "@coreui/react";
import "./index.css";

const Navbar = React.lazy(() => import("../../Navbar"));

const Footer = React.lazy(() => import("../footer"));

const AdminCareers = () => {
  const history = useHistory();
  const [careersSection, setCareersSection] = useState([]);
  const [careersCompanyLogosList, setCareersCompanyLogosList] = useState([]);

  useEffect(() => {
    // Fetch whatWeDoSection data from the backend when the component mounts
    axios
      .get(`${logigloUrl}/careersSection`)
      .then((response) => {
        console.log(response.data, "careersSection");
        setCareersSection(response.data.careersSection);
        setCareersCompanyLogosList(response.data.companyLogos);
        // console.log(organizedArray);
      })
      .catch((error) => {
        console.error("Error fetching careersSection data:", error);
      });
  }, []);


  const onClickAdminCareersBackBtn = () => {
    history.push('/')
  }

  const onClickViewCareersBtn = () => {
    history.push('/Careers/admin/modify')
  }

  return (
    <LayoutWrapper>
      <FullColumn>
        <div className="admin-create-careers-form-con">
        <Suspense fallback={<div>Loading...</div>}>
            <Navbar />
          </Suspense>
          {/* <Link to="/Careers/admin/modify" style={{textDecoration : 'none', color: 'black'}}> */}
          {/* <p >View Career posts</p> */}

          <CCol className="admin-careers-container-styles" >

         

          {/* </Link> */}

          {/* <CButton
            className="btn careers-back-btn"
            color="primary"
            type="submit"
            style={{ marginRight: "20px", backgroundColor: "#155CA2", marginLeft: '10px'}}
            onClick={onClickAdminCareersBackBtn}
          >
            Back
          </CButton> */}


          <CButton
            className="btn careers-back-btn"
            color="primary"
            type="submit"
            style={{ marginRight: "20px", backgroundColor: "#155CA2", marginLeft: '5px'}}
            onClick={onClickViewCareersBtn}
          >
            View Jobs
          </CButton>
          </CCol>

          <div className="career-main-con">
         
        
          {/* <div className="career-img">
            {careersSection.map((eachItem) => (
  
                <div className="d-none d-sm-block">
                  <img
                    className="career-img"
                    src={eachItem.section_img_url}
                    alt="Career-Image"
                  />
                </div>
    
            ))}

</div> */}

             <div className="career-form">
            <CareersForm />
              </div> 


  {/* {careersSection.map((eachItem) => (
          <div className="Career-comb-con">
            <div className="d-none d-sm-block career-left-con">
              <img
                className="career-img"
                src={eachItem.section_img_url}
                alt="Career-Image"
              />
            </div>

            <div className="career-right-con">
              <div className="career-text-con">
                <h1 className="career-text">{eachItem.title_1}</h1>
                <h1 className="career-text-span">{eachItem.title_2}</h1>
                <div className="search-input-and-icon-con">
                  <input className="search-input" placeholder="Search" />
                  <SearchFavoriteIcon />
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="companies-container">
          <h3 className="companies-sec-text">Employers of Choice</h3>
          <div className="company-logo-con">
            {careersCompanyLogosList.map((eachItem) => (
              <img
                src={eachItem.company_logo_url}
                className="copany-logo-styles"
              />
            ))}
          </div>
        </div> */}
          
          </div>
          <Suspense fallback={<div>Loading...</div>}>
            <Footer />
          </Suspense>
        </div>
      </FullColumn>
    </LayoutWrapper>

 
  );
};

export default AdminCareers;


   // <div className="careers-home">
    //   <Navbar />
    //   <div className="career-main-con" >

    //     <h3 className="shop-section-header-styles">Launching Soon</h3>

    //   </div>
    //   <Footer/>
    // </div>