import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';



const useStyles = makeStyles(theme => ({

 

  search: {
    
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 520,
     
    },
  },
}));




export default function TopbarSearch({onChange=false}) {
  
  // const account_id = useSelector(state => state.Dashboard.account_id)
  // console.log(searchText, "in_topbar_search")

  const history = useHistory();
  const classes = useStyles();

  const account_id = useSelector((state) => state.Dashboard.account_id);
  console.log(account_id, 'from redux store')

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Code to navigate to the "/search" route
      history.push(`/forumDashboard/searchPage?q=${e.target.value}`, "_blank");
    }
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(event)=>{onChange && onChange(event)}}
        onKeyPress={handleKeyPress}
      />
    </div>
  );
}
