import { SET_COLOR } from './actions';

// Initial state
const initialState = {
  color: '#155CA2', // default color
};

// Reducer function
const colorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COLOR:
        console.log('received', action.payload);
      return {
        ...state,
        color: action.payload,
      };
    default:
      return state;
  }
};

export default colorReducer;
