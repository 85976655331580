import { Link, useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import signinImg from "../../../images/signin.svg";
import fbBtnSvg from "../../../images/facebook-app-symbol.svg";
import gpBtnSvg from "../../../images/google-plus.svg";
import authBtnSvg from "../../../images/auth0.svg";
import TextField from "../../../components/uielements/textfield";
import Scrollbars from "../../../components/utility/customScrollBar";
import Button from "../../../components/uielements/button";
import authAction from "../../../redux/auth/actions";
import IntlMessages from "../../../components/utility/intlMessages";
import SignUpStyleWrapper from "./signup.style";
import Auth0 from "../../../helpers/auth0/index";
import Firebase from "../../../helpers/firebase";
import FirebaseLogin from "../../../components/firebase";
import { Checkbox } from "./signup.style";
import Select from "react-select";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { logigloUrl } from '../../Config/index'
import "./signup.css";
import { useNavigate } from "react-router-dom";
import { add } from "date-fns";
// import 'react-select/dist/react-select.css';

const { login } = authAction;

const CorporateAccount = () => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const account_id = new URLSearchParams(location.search).get("account_id");
  const isLoggedIn = useSelector((state) => state.Auth.idToken !== null);
  const [bussAccName, setBussAccName] = useState("");
  const [role, setRole] = useState("");
  const [cinNUmber, setCinNumber] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [entityType, setEntityType] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [countryId, setCountryId] = useState("");
  const [signupResponse, setSignupResponse] = useState("");
 

  const handleLogin = () => {
    dispatch(login());
    history.push("/forumDashboard");
  };

  const countryOptions = [
    { value: "India", label: "India" },
    { value: "USA", label: "USA" },
    { value: "Australia", label: "Australia" },
    // Add more states as needed
  ];

  const companyType = [
    { value: 'LLP', label: "LLP" },
    { value: 'PVT', label: "PVT" },
    // Add more states as needed
  ];

  const customStyles = {
    // Style for the container that wraps the Select component
    container: (provided, state) => ({
      ...provided,
      width: "200px", // Set your desired width
      backgroundColor: "#f3fbff",
      
    }),
    // Style for the dropdown options
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#f3fbff" : "white",
      color: state.isSelected ? "#f3fbff" : "black",
      borderTop: "0",
      borderLeft: "0",
      borderRight: "0", 
      marginTop : '28px'
      // Add more styles as needed
    }),
    // Style for the control (input field)
    control: (provided, state) => ({
      ...provided,
      border: "1px solid gray",
      borderRadius: "4px",
      backgroundColor: "#f3fbff",
      borderTop: "0",
      borderLeft: "0",
      borderRight: "0", 
      marginTop : '27px'
      // Add more styles as needed
    }),
    // Add more custom styles for other elements
  };

  // const handleSubmit = (e) => {
  //   // e.preventDefault();
  //   const userData = {
      
  //     name: bussAccName,
  //      cin : cinNUmber, 
  //      gstin : gstNumber, 
  //      entity_type : entityType, 
  //      verified : false, 
  //      address, 
  //      city,
  //      country : countryId

     
      
  //   };

  //   axios
  //     .post(`http://13.48.70.15/api/createUser", userData)
  //     .then((response) => {
  //       console.log(response.data.message);
  //       if (response.data.message === "User Created Successfully") {
  //         const [accountId] = response.data.contactValues;
  //         console.log(accountId);
  //         setSignupResponse(
  //           "Business Account Created Successfully, Please verification Account Manually"
  //         ); // Set the response message received from the server

  //         // Reset the form fields
  //         setBussAccName("");
  //         setCinNumber("");
  //         setGstNumber("");
  //         setCountryId("");
  //         setAddress("");
  //         setCity("");
  //         setEntityType("");
    

  //         window.open(
  //           `/dashboard/interestsPage?account_id=${accountId}`,
  //           "_blank"
  //         );
  //       } else {
  //         setSignupResponse(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         // Request was made and server responded with a status code outside the range of 2xx
  //         console.error(error.response.data);
  //         setSignupResponse(error.response.data);
  //       } else if (error.request) {
  //         // The request was made but no response was received
  //         console.error(error.request);
  //         setSignupResponse("Error creating user");
  //       } else {
  //         // Something happened in setting up the request that triggered an Error
  //         console.error("Error:", error.message);
  //         setSignupResponse("Error creating user");
  //       }
  //     });
  // };



  const handleSubmit = (e) => {
    // Create an object with user data
    const userData = {
      name: bussAccName,
      role,
      cin: cinNUmber,
      gstin: gstNumber,
      entity_type: entityType,
      verified: false,
      address,
      city,
      country: countryId,
      account_id: account_id,
    };
  
    // Send a POST request to your backend API
    axios
      .post(`${logigloUrl}/createBusinessAccount`, userData)
      .then((response) => {
        console.log(response.data.message);
        if (response.data.message === "Business Account created successfully") {
          const accountId = response.data.id; // Assuming the API returns the ID of the created account
          console.log(accountId);
          setSignupResponse(
            "Business Account Created Successfully, Please verify the Account Manually"
          );
  
          // Reset the form fields
          setBussAccName("");
          setRole("");
          setCinNumber("");
          setGstNumber("");
          setCountryId("");
          setAddress("");
          setCity("");
          setEntityType("");
  
          // Open a new window with the account ID (you can customize the URL)
          window.open(
            `/forumDashboard/BussAccountVerification?account_id=${accountId}`,
            "_blank"
          );
        } else {
          setSignupResponse(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response) {
          // Request was made and server responded with a status code outside the range of 2xx
          console.error(error.response.data);
          setSignupResponse(error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error(error.request);
          setSignupResponse("Error creating user");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error:", error.message);
          setSignupResponse("Error creating user");
        }
      });
  };
  

  return (
    <SignUpStyleWrapper className="mateSignUpPage">
      <div className="mateSignInPageImgPart">
        <div className="mateSignInPageImg">
          <img src={signinImg} alt="Kiwi standing on oval" />
        </div>
      </div>

      <div className="mateSignInPageContent">
        <div className="mateSignInPageLink">
          <Link to="#">
            <button className="mateSignInPageLinkBtn active" type="button">
              Business Account Details
            </button>
          </Link>
         
        </div>
        <Scrollbars style={{ height: "100%" }}>
         
          <div className="mateSignInPageForm">
            <div className="mateInputWrapper">
              <TextField
                label="Name"
                placeholder="Name"
                margin="normal"
                onChange={(e) => setBussAccName(e.target.value)}
                required
                value={bussAccName}
              />
            </div>

            <div className="mateInputWrapper">
              <TextField
                label="Role"
                placeholder="Role"
                margin="normal"
                onChange={(e) => setRole(e.target.value)}
                required
                value={role}
              />
            </div>

            <div className="mateInputWrapper">
              <TextField
                label="CIN"
                placeholder="CIN"
                margin="normal"
                onChange={(e) => setCinNumber(e.target.value)}
                required
                value={cinNUmber}
              />
            </div>
            <div className="mateInputWrapper">
              <TextField
                label="GSTIN"
                placeholder="GSTIN"
                margin="normal"
                onChange={(e) => setGstNumber(e.target.value)}
                required
                value={gstNumber}
              />
            </div>

          

            <div className="mateInputWrapper">
              <Select
                // className="signup-input"
                styles={customStyles}
                name="Entity Type"
                placeholder="Entity Type*"
                options={companyType}
                value={companyType.find((option) => option.value === entityType)}
                onChange={(selectedOption) => setEntityType(selectedOption.value)}
                required
              />
            </div>

            <div className="mateInputWrapper">
              <Select
                label="country"
                // className="signup-input"
                name="countryId"
                placeholder="Country*"
                styles={customStyles}
                options={countryOptions}
                value={countryOptions.find(
                  (option) => option.value === countryId
                )}
                onChange={(selectedOption) =>
                  setCountryId(selectedOption.value)
                }
                required
              />
            </div>
          
           


            <div className="mateInputWrapper">
              <TextField
                label="Address"
                placeholder="Address"
                margin="normal"
                onChange={(e) => setAddress(e.target.value)}
                required
                value={address}
              />
            </div>

            <div className="mateInputWrapper">
              <TextField
                label="City"
                placeholder="City"
                margin="normal"
                onChange={(e) => setCity(e.target.value)}
                required
                value={city}
              />
            </div>

           

            
           
           
          </div>
          <div className="mateAgreement">
            
            <div className="mateLoginSubmit">
              <Button type="button" onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </div>
          <div className="mateLoginSubmitText">
            <span>{signupResponse}</span>
          </div>

          
        </Scrollbars>
      </div>
    </SignUpStyleWrapper>
  );
};

export default CorporateAccount
