import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

// For Google Console and AdSense
const CanonicalLink = () => {
  const location = useLocation();

  useEffect(() => {
    const canonicalUrl = window.location.origin + location.pathname;
    console.log("CurrentPageURL", canonicalUrl); // Log current page URL

    const existingCanonical = document.querySelector('link[rel="canonical"]');

    if (existingCanonical) {
      existingCanonical.setAttribute("href", canonicalUrl); // Update existing canonical URL
    } else {
      const canonicalLink = document.createElement("link");
      canonicalLink.setAttribute("rel", "canonical");
      canonicalLink.setAttribute("href", canonicalUrl);
      document.head.appendChild(canonicalLink); // Add new canonical URL
    }



     // Add Google AdSense script
     const adSenseScript = document.createElement("script");
     adSenseScript.async = true;
     adSenseScript.src =
       "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2611364210790816";
     adSenseScript.crossOrigin = "anonymous";
     document.head.appendChild(adSenseScript);

  

  }, [location.pathname]);

  return null; // This component doesn't render anything in the DOM
};

export default CanonicalLink;
