import { getDefaultPath } from '../../helpers/urlSync';

const options = [
  // {
  //   label: 'sidebar.dashboard',
  //   key: 'dashboard',
  // },
  // {
  //   label: 'Posts',
  //   key: 'Posts',
  // },
  // {
  //   label: '  a',
  //   key: 'Private Page',
  // },
  // {
  //   label: ' ',
  //   key: 'createReply',
  // },
  // {
  //   label: ' c',
  //   key: 'template four',
  // },
  // {
  //   label: ' ',
  //   key: 'searchPage',
  // },
  // {
  //   label: ' a',
  //   key: 'createPost',
  // },
  // {
  //   label: '  ',
  //   key: 'interestsPage',
  // },
  // {
  //   label: '  c',
  //   key: 'verifyEmail',
  // },
];
const getBreadcrumbOption = () => {
  const preKeys = getDefaultPath();
  let parent, activeChildren;
  options.forEach(option => {
    if (preKeys[option.key]) {
      parent = option;
      (option.children || []).forEach(child => {
        if (preKeys[child.key]) {
          activeChildren = child;
        }
      });
    }
  });
  return { parent, activeChildren };
};
export default options;
export { getBreadcrumbOption };
