// import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import { createBrowserHistory } from 'history';
// import { connectRouter, routerMiddleware } from 'connected-react-router';
// import thunk from 'redux-thunk';
// import createSagaMiddleware from 'redux-saga';
// import reducers from './reducers';
// import rootSaga from './sagas';
// import colorReducer from './buttonColor/reducer';

// const history = createBrowserHistory();
// const sagaMiddleware = createSagaMiddleware();
// const routeMiddleware = routerMiddleware(history);
// const middlewares = [thunk, sagaMiddleware, routeMiddleware];
// const composeEnhancers =
//   typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//         // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//       })
//     : compose;

// const store = createStore(
//   combineReducers({
//     ...reducers,
//     colorReducer,
//     router: connectRouter(history),
//   }),
//   composeEnhancers(applyMiddleware(...middlewares))
// );
// sagaMiddleware.run(rootSaga);
// export { store, history };




import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';
import colorReducer from './buttonColor/reducer'; // Import colorReducer

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const rootReducer = combineReducers({
  ...reducers,
  colorReducer, // Add colorReducer here
  router: connectRouter(history),
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

export { store, history };




// import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import { createBrowserHistory } from 'history';
// import { connectRouter, routerMiddleware } from 'connected-react-router';
// import thunk from 'redux-thunk';
// import createSagaMiddleware from 'redux-saga';
// import reducers from './reducers';
// import rootSaga from './sagas';
// import colorReducer from './buttonColor/reducer'; // Import colorReducer

// const history = createBrowserHistory();
// const sagaMiddleware = createSagaMiddleware();
// const routeMiddleware = routerMiddleware(history);

// const rootReducer = combineReducers({
//   ...reducers,
//   color: colorReducer, // Update the key to `color`
//   router: connectRouter(history),
// });

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware()
//       .concat(thunk)
//       .concat(sagaMiddleware)
//       .concat(routeMiddleware),
//   devTools: process.env.NODE_ENV !== 'production',
// });

// sagaMiddleware.run(rootSaga);

// export { store, history };



