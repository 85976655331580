import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { logigloUrl } from "../../Config/index";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FullColumn } from "../../../components/utility/rowColumn";
import careers from "../../../assests/careers.svg";
import education from "../../../assests/education.svg";
import ExportIcon from "../../../assests/export";
import LayoutWrapper from "../../../components/utility/layoutWrapper";
import "./index.css";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CFormTextarea,
  CLink,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CPopover,
  CTooltip,
  CToastHeader,
  CToastBody,
  CToast,
  CToaster,
  CFormSwitch,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CWidgetStatsA,
  CListGroup,
  CListGroupItem,
  CDropdownDivider,
  CCardTitle,
  CCardText,
  CCardFooter,
} from "@coreui/react";
const Navbar = React.lazy(() => import("../../Navbar"));
const Footer = React.lazy(() => import("../../LandingPageComponents/footer"));

const CourseDetails = () => {
  const [visible, setVisible] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  const { id } = useParams();
  const [courseDetail, setCourseDetail] = useState(null);

  useEffect(() => {
    const fetchCourseDetail = async () => {
      try {
        const response = await axios.get(`${logigloUrl}/getCourse/${id}`);
        setCourseDetail(response.data);
      } catch (error) {
        console.error("Error fetching course detail:", error);
      }
    };

    fetchCourseDetail();
  }, [id]);

  // const handlePlayVideo = (videoUrl) => {
  //   // Extract the video ID from the URL
  //   const videoId = videoUrl.split("v=")[1]?.split("&")[0];

  //   console.log(videoId, "vvv_iiid");
  //   // Construct the embed URL
  //   const embedUrl = `https://www.youtube.com/embed/${videoId}`;
  //   setSelectedVideo(embedUrl);
  //   setVisible(true);
  // };


 const handlePlayVideo = (videoUrl) => {
  
  const videoIdMatch = videoUrl.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?.*v=))([^"&?\/\s]{11})/);
  if (!videoIdMatch) {
    console.error("Unsupported YouTube URL format");
    return;
  }

  const videoId = videoIdMatch[1];

    console.log(videoId, "vvv_iiid")
  
    // Construct the embed URL
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    setSelectedVideo(embedUrl);
    setVisible(true);
  };

  

  const handleCloseModal = () => {
    // Clear the selected video and hide the modal
    setSelectedVideo("");
    setVisible(false);
  };

  if (!courseDetail) return <div>Loading...</div>;

  return (
    <LayoutWrapper>
        <Navbar />
      <div className="course-detail-con">
        <div className="course-detail-top-con">
          <div className="course-text-container"
          >
            <h1>{courseDetail.course_title}</h1>
            <h4>{courseDetail.course_text}</h4>
            <CButton
              color="primary"
              onClick={() => handlePlayVideo(courseDetail.course_url)}
            >
              Play Video
            </CButton>
          </div>
          <img
            src={courseDetail.course_img}
            alt="course_img"
            className="course-img"
          />
        </div>
        <div className="course-each-section">
          <h4>Introduction:</h4>
          <p>{courseDetail.introduction}</p>
        </div>

        <div className="course-each-section">
        <h4>Description:</h4>
          <p>{courseDetail.detailed_description}</p>
        </div>

        <CModal visible={visible} onClick={handleCloseModal} size="lg">
          <CModalHeader onClick={handleCloseModal}>
            <CModalTitle>Video Player</CModalTitle>
          </CModalHeader>
          <CModalBody>
            {selectedVideo && (
              <iframe
                width="100%"
                height="400"
                src={selectedVideo}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </CModalBody>
        </CModal>
      </div>
    </LayoutWrapper>
  );
};

export default CourseDetails;
