// import actions from "./actions";

// const initState = { idToken: null };

// export default function authReducer(state = initState, action) {
//   switch (action.type) {
//     case actions.LOGIN_SUCCESS:
//       return {
//         idToken: action.token
//       };
//     case actions.LOGOUT:
//       return initState;
//     default:
//       return state;
//   }
// }



import actions from "./actions";

const initState = { idToken: null, accountType: null  };
// const a_type = {accountType : null}

export default function authReducer(state = initState, action) {
  console.log("action2 => ",action)
  console.log("payload2 => ",action?.payload)
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        accountId: action.accountId,
        accountType:action.accountType
      };
    case actions.LOGOUT:
      return initState;
    case actions.ACCOUNT_TYPE:
      return {
        ...state,
        accountType:action.accountType
      };
    default:
      return state;
  }
}
